<!--
 * @Author       : JiangChao
 * @Date         : 2023-04-18 09:38:16
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-05-06 09:17:33
 * @Description  : 订阅制
-->
<template>
    <div class="container">
        <div class="bg" :style="{ minHeight: boxheight }">
            <!-- <p style="font-size:14px;text-align:left">支付新台幣384元押金，即可立即使用</p> -->
            <div class="card">
                <div>訂閱</div>
                <van-divider></van-divider>
                <van-cell title="訂閱時間：" style="text-align: left">
                </van-cell>
                <van-cell
                    :title="`${startExpireDate}至` + `${expireDate}`"
                    style="text-align: left"
                >
                </van-cell>
            </div>

            <div class="tip">
                <p>說明：</p>
                <p>預先支付費用，可享訂閱期間不限時間與次數，無限制使用</p>
                <p>綁定後每月會自動扣款續約，如不續用請取消訂閱</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {
            startExpireDate: "",
            expireDate: "",
        };
    },
    methods: {
        $_href(page) {
            if (!page) return;
            if (page.indexOf("https") !== -1) {
                window.location.href = page;
            } else {
                this.$router.push(page);
            }
        },
    },
    props: {},
    components: {},
    computed: {
        boxheight() {
            return `${window.innerHeight}px`;
        },
    },
    mounted() {
        
        this.expireDate = localStorage.getItem("expireDate");
        if (this.expireDate == "null") {
            this.expireDate = "";
        }
        this.startExpireDate = localStorage.getItem("startExpireDate");
        if (this.startExpireDate == "null") {
            this.startExpireDate = "";
        }
    },
    created() {},
};
</script>

<style scoped lang="less">
.bg {
    background: #f8f8f8;
    text-align: center;
    padding: 20px;
    .logo {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background: #ffffff;
        margin-top: 20px;
        border-radius: 10px;
        line-height: 50px;
    }
    .card {
        border-radius: 10px;
        margin-top: 20px;
    }
    .login-way {
        .sms-way {
            background: #2c394e;
            border-radius: 25px;
            height: 50px;
            color: #ffffff;
            line-height: 50px;
            margin: 20px;
            text-align: center;
        }
    }
    .tip {
        margin-top: 20px;
        text-align: left;
        margin-bottom: 30px;
        padding: 5px;
        p {
            font-size: 12px;
        }
    }
    .read-moudle {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        .clause {
            font-size: 12px;
            font-family: SourceHanSansCN, SourceHanSansCN-Regular;
            font-weight: 400;
            text-align: left;
            line-height: 12px;
            margin-left: 10px;
        }
    }
    .login-way {
        .sms-way {
            background: #2c394e;
            border-radius: 25px;
            height: 50px;
            color: #ffffff;
            line-height: 50px;
            margin: 20px;
            text-align: center;
        }
    }
}
</style>
